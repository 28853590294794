(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:OrderCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('OrderCtrl', OrderCtrl);

  function OrderCtrl($scope, $state, FormControl, OrderService, isAuthenticated, _, LoggedUser, $analytics, $translate, $mdDialog, $cookies, orderType, company) {
    var vm = this
      , formControl = new FormControl()
      , cardDataStateIndex = 1;

    vm.activeStateIndex = 0;
    vm.selectedProductType;

    //initExitIntent();

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    vm.isAuthenticated = isAuthenticated;

    vm.prevStep = function prevStep(newState, prevState) {
      vm.activeStateIndex--;

      $state.go(newState);
    };

    vm.nextStep = function nextStep(newState, prevState) {
      var analyticsValue = ''
        , analyticsDatas = {}
        , action = '';

      vm.activeStateIndex++;

      switch (newState) {
        case 'itemList':
          analyticsValue = 1;
          break;
        case 'fileUpload':
          analyticsValue = 2;
          break;
        default:
          analyticsValue = '';
          break;
      }

      if (!LoggedUser.isAuthenticated()) {
        action = $translate.instant('order.steps.analytics.' + prevState + '.anonymous') + ' - ' + $translate.instant('order.steps.analytics.stepNext');

        analyticsDatas = {
          category: $translate.instant('order.steps.analytics.registration'),
          action: action,
          label: company.vatNumber
        };

        if (analyticsValue !== '') {
          analyticsDatas.value = analyticsValue;
        }

        $analytics.eventTrack(action, analyticsDatas);
      } else {
        action = $translate.instant('order.steps.analytics.' + prevState + '.authenticated') + ' - ' + $translate.instant('order.steps.analytics.stepNext');

        analyticsDatas = {
          category: $translate.instant('order.steps.analytics.registration.' + orderType),
          action: action,
          label: company.vatNumber
        };

        if (analyticsValue !== '') {
          analyticsDatas.value = analyticsValue;
        }

        $analytics.eventTrack(action, analyticsDatas);
      }

      $state.go(newState);
    };

    $scope.$on('order.next', function ($event, newState, prevState) {
      vm.nextStep(newState, prevState);
    });

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    function onStateChangeSuccess(event, toState, toParams, fromState) {
      var stateToGo = null;

      if (toState.name === 'order') {
        stateToGo = (isAuthenticated) ? 'typeSelector' : 'company-basic';
        vm.activeStateIndex = 0;
      }

      if (fromState.name === 'multiWalletDataReconciliationList') {
        vm.activeStateIndex = cardDataStateIndex;
      }

      OrderService.setActiveStateIndex(vm.activeStateIndex);

      if (!_.isNull(stateToGo)) {
        $state.go(stateToGo);
      }
    }

    /*function initExitIntent() {
      var shownExitIntentDialog = $cookies.get('shownExitIntentDialog');

      if (shownExitIntentDialog || LoggedUser.isAuthenticated()) {
        return;
      }

      document.addEventListener('mouseleave', handleExitIntent);
    }

    function handleExitIntent() {
      $cookies.put('shownExitIntentDialog', true);
      document.removeEventListener('mouseleave', handleExitIntent);

      $mdDialog.show({
        parent: angular.element(document.body),
        templateUrl: 'wb-order/views/exit-intent-dialog.tpl.html',
        clickOutsideToClose: true,
        focusOnOpen: false,
        controllerAs: 'exitIntentDialog',
        controller: function exitIntentDialog($mdDialog, FormControl, $q, Order, $analytics) {
          var vm = this
            , formControl = new FormControl();

          formControl.setSuccessText('order.exitIntentDialog.sent');

          vm.exitData = {};

          vm.closeDialog = function closeDialog() {
            $mdDialog.hide();
          };

          vm.isProcessing = function isProcessing() {
            return formControl.isProcessing();
          };

          vm.getServerValidation = function getServerValidation() {
            return formControl.getServerValidation();
          };

          vm.saveExitData = function saveExitData() {
            formControl
              .process(Order.saveExitData(vm.exitData).$promise)
              .then(function () {
                $analytics.eventTrack($translate.instant('order.steps.analytics.exitIntent.action'), {
                  category: $translate.instant('order.steps.analytics.exitIntent.category'),
                  action: $translate.instant('order.steps.analytics.exitIntent.action'),
                  label: vm.exitData.name + ' - ' + vm.exitData.companyName
                });

                $mdDialog.hide();
              });
          };
        }
      });
    }

    $scope.$on('$destroy', function () {
      document.removeEventListener('mouseleave', handleExitIntent);
    });*/
  }
}());
